import React, { useState } from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"
import Spaceship from "../components/svg/Spaceship"

const Resources = ({ data }) => {
  const [activeFilter, setActiveFilter] = useState("All")

  const allResources = data.allContentfulResource.edges.map(
    (elem: any) => elem.node
  )

  const filteredResources = allResources.filter(resource => {
    if (activeFilter === "All") return true
    return resource.type === activeFilter
  })

  return (
    <Layout absoluteHeader>
      <SEO
        description="Utilize our library of simple to use gamified resources, each curated to leverage science, gamification, and the latest in AI wellness and habit modification. "
        link="https://mooremomentum.com/resources/"
        title="Free Gamification Resources"
        image="https://images.ctfassets.net/8kplw9flsxgi/1UWjHItTdkTXE2scQvKJip/467a5059aaba6b863134615fd246fd8e/Emmett-Happy-Town-V2.png"
      />
      <div className="overflow-hidden relative">
        <div className="black-gradient-bg pt-24 sm:pt-32 mb-12 md:pt-28 h-lvh">
          <div className="container mx-auto px-4 relative text-white z-10">
            <div className="relative sm:order-1 ">
              <div className="space-y-4 text-link mt-28">
                <h2 className="font-Orbitron text-2xl lg:text-4xl mb-0 mt-12 text-center md:text-left md:pb-0 pb-4">
                  MM Resource ARCADE
                </h2>
                <p className="text-xl md:block hidden">
                  Free Access To Gamified Resources.
                </p>
                <div
                  className="flex items-center relative z-10"
                  id="hero-quiz-button"
                >
                  <StaticImage
                    class="m-auto md:mb-28 mb-4 animate-pulse"
                    alt="logo"
                    formats={["png"]}
                    placeholder="blurred"
                    src="../images/arcade.png"
                  />
                </div>
                <h2 className="font-Orbitron text-2xl lg:text-4xl text-center md:text-left md:hidden block pb-28">
                  Free Access To Gamified Resources.
                </h2>
              </div>
              <div className="absolute bottom-full w-16 z-10 sm:w-20 md:w-24 lg:rotate-0">
                <Spaceship />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filter Menu */}
      <div className="container mx-auto px-4 mb-8">
        <div className="flex justify-start gap-4">
          {[
            "All",
            "Personalized Momentum Lists",
            "Dopamine Menu",
            "Routine's Lists",
          ].map(filter => (
            <span
              key={filter}
              onClick={() => setActiveFilter(filter)}
              className={`cursor-pointer px-4 py-2 rounded-lg transition-all duration-300 text-xl ${
                activeFilter === filter
                  ? "bg-coral text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              {filter}
            </span>
          ))}
        </div>
      </div>

      <div className="container mx-auto md:py-10 py-0">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {filteredResources?.map(elem => (
            <div key={elem.id} className="bg-white p-6 rounded-lg shadow-lg">
              <GatsbyImage
                alt={
                  elem.image?.description ? elem.image?.description : elem.title
                }
                image={elem.image?.gatsbyImageData}
                className="mb-4"
                objectFit="contain"
                style={{ height: "370px", width: "100%" }}
              />
              <h2 className="text-xl font-semibold mb-2 mt-4">{elem?.title}</h2>
              <p className="text-gray-600 mb-4" style={{ minHeight: "130px" }}>
                {elem?.metaDescription}
              </p>
              <Link to={`/resource/${elem?.slug}`}>
                <button className="resource-btns">Get</button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div id="phaser-container" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulResource(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          image {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          date
          id
          metaDescription
          slug
          title
          type
        }
      }
    }
  }
`

export default Resources
